function readCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function createCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  }
  document.cookie = `${name}=${value}${expires};domain=siteintelhub.com; path=/`;
}

function deleteCookie(name, path, domain) {
  if (readCookie(name)) {
    document.cookie = `${name}=${path ? `;path=${path}` : ""}${
            domain ? `;domain=${domain}` : ""
        };expires=Thu, 01-Jan-1970 00:00:01 GMT`;
  }
}

exports.cookies = {
  readCookie,
  createCookie,
  deleteCookie
};
