import axios from "axios";

import * as types from "./types";
import {
  API_URL,
  ACTIVE, CANCELED, PENDING, SUSPENDED,
  formatUnixTimestamp
} from '../common/constants';
// user actions

export const getListings = (params = {}) => {
  return async (dispatch) => {
    try {
      // Make an API call to send registration data
      const response = await axios.post(`${API_URL}/listing/list`, params);

      if (response.status === 200) {
        const listings = response.data;
        dispatch({
          type: 'GET_LISTINGS',
          payload: listings
        });

        return true;
      } else {
        //throw new Error('Registration failed');
        return false;
      }
    } catch (error) {
      console.log('Error registering user: ', error);
      return false;
    }
  };
};
