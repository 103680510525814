// 
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const CHECK_TOKEN = "CHECK_TOKEN";
export const VALIDATE = "VALIDATE";
export const SET_COMPANY = "SET_COMPANY";
export const GET_LOGINS = "GET_LOGINS";

export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_USERS = "GET_USERS";
export const GET_USER_DESIGNATIONS = "GET_USER_DESIGNATIONS";
export const USER_PURCHASE = "USER_PURCHASE";

export const CREATE_BILLING = "CREATE_BILLING";
export const UPDATE_BILLING = "UPDATE_BILLING";
export const GET_BILLINGS = "GET_BILLINGS";

export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";

export const MAP_LAYERS = "MAP_LAYERS";
export const UPDATE_LAYERS = "UPDATE_LAYERS";
export const CLEAR_LAYERS = "CLEAR_LAYERS";

export const CREATE_PROJECT = "CREATE_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const GET_PROJECTS = "GET_PROJECTS";

export const GET_LISTINGS = "GET_LISTINGS";
export const CREATE_LISTING = "CREATE_LISTING";
export const UPDATE_LISTING = "UPDATE_LISTING";
