import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

const Blurb = () => {
  return (
    <>
      <div
        className="section section-basic-components"
        data-background-color="dark-blue"
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="5" md="7">
              <h2 className="title">Comprehensive Data Layers Integration</h2>
              <h6 className="category">Gateway to Commercial Real Estate Insights and Solutions</h6>
              <h5 className="description">
                Unlock the full potential of data visualization with our Site Intel Hub.
                This innovative platform allows users to layer multiple data sources from
                local, state and national entities, on a single map, providing a clear
                visual representation of areas of interest along with key influencing
                factors.
              </h5>
            </Col>
            <Col lg="6" md="12">
              <div className="image-container">
                <img
                  alt="..."
                  className="components-macbook"
                  src={require("assets/img/ipad.png")}
                ></img>
                {
                  /*
                  <img
                    alt="..."
                    className="table-img"
                    src={require("assets/img/presentation-page/table.jpg")}
                  ></img>
                  <img
                    alt="..."
                    className="share-btn-img"
                    src={require("assets/img/presentation-page/share-btn.jpg")}
                  ></img>
                  <img
                    alt="..."
                    className="coloured-card-btn-img"
                    src={require("assets/img/presentation-page/coloured-card-with-btn.jpg")}
                  ></img>
                  <img
                    alt="..."
                    className="coloured-card-img"
                    src={require("assets/img/presentation-page/coloured-card.jpg")}
                  ></img>
                  <img
                    alt="..."
                    className="social-img"
                    src={require("assets/img/presentation-page/social-row.jpg")}
                  ></img>
                  <img
                    alt="..."
                    className="linkedin-btn-img"
                    src={require("assets/img/presentation-page/linkedin-btn.jpg")}
                  ></img>
                  */
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Blurb;
