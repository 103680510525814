import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

import ListingCard from './ListingCards/ListingCard';
import ListingActiveCard from './ListingCards/ListingActiveCard';

const Listings = () => {
  const listingState = useSelector(state => state.listingReducer);

  return (
    <>
      <div
        className="section section-cards"
        data-background-color="gray"
        id="cards"
      >
        <div className="cards">
          <Container>
            <div className="title">
              <h3>
                Listing Opportunites
              </h3>
            </div>
            <Row>
              {
                /*
                <Col lg="3" md="6">
                  <Card className="card-profile">
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/julie.jpg")}
                      ></img>
                    </div>
                    <CardBody>
                      <a href="pablo">
                        <CardTitle tag="h4">Julie Andrew</CardTitle>
                      </a>
                      <h6 className="card-category text-info">Web Designer</h6>
                      <CardFooter>
                        <Button
                          className="btn-icon btn-neutral"
                          color="twitter"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          size="lg"
                        >
                          <i className="fab fa-twitter"></i>
                        </Button>
                        <Button
                          className="btn-icon btn-neutral"
                          color="dribbble"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          size="lg"
                        >
                          <i className="fab fa-dribbble"></i>
                        </Button>
                        <Button
                          className="btn-icon btn-neutral"
                          color="instagram"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          size="lg"
                        >
                          <i className="fab fa-instagram"></i>
                        </Button>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Col>
                */
              }
              {listingState?.listings?.map((listing, index) =>
                <>
                  <Col lg="3" md="6">
                    <ListingCard listing={listing} key={`listng-${index}`} />
                  </Col>
                </>
              )}
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Listings;
