import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

const ListingCard = ({ listing }) => {
  return (
    <>
      <Card className="card-profile">
        <div className="card-avatar">
          <a href={`#${listing.listing_name}`} onClick={(e) => e.preventDefault()}>
            <img
              alt="..."
              className="img img-raised"
              src={
                require("assets/img/image_placeholder.jpg")
                //listing?.featured_image?.attachment_location
              }
            ></img>
          </a>
        </div>
        <CardBody>
          <CardTitle tag="h4">{ `For ${listing.list_type} ${listing.price_string}`}</CardTitle>
          <h6 className="category text-gray">{ listing.listing_title }</h6>
          <p className="card-description">
            { 
              //listing.listing_title 
            }
          </p>
          <CardFooter>
            { /*
            <Button
              className="btn-icon btn-round"
              color="twitter"
              href={`#${listing.listing_name}`}
              onClick={(e) => e.preventDefault()}
            >
              <i className="fab fa-twitter"></i>
            </Button>
            <Button
              className="btn-icon btn-round"
              color="facebook"
              href={`#${listing.listing_name}`}
              onClick={(e) => e.preventDefault()}
            >
              <i className="fab fa-facebook-square"></i>
            </Button>
            <Button
              className="btn-icon btn-round"
              color="google"
              href={`#${listing.listing_name}`}
              onClick={(e) => e.preventDefault()}
            >
              <i className="fab fa-google"></i>
            </Button>
            */}
            { /*
            <Button
              className="btn-info"
              href={`#${listing.listing_name}`}
              onClick={(e) => e.preventDefault()}
            >
              Get Details 
            </Button>
            */}
          </CardFooter>
        </CardBody>
      </Card>

    </>
  );
};

export default ListingCard;
