import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './authReducer';
import userReducer from './userReducer';
import listingReducer from './listingReducer';

const rootReducer = combineReducers({
  authReducer,
  userReducer,
  listingReducer,
  form: formReducer
});

export default rootReducer;
