import React from "react";
import { withRouter, Link } from "react-router-dom"; 
import { useSelector, useDispatch } from 'react-redux';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Tooltip
} from "reactstrap";

// core components
// import FixedTransparentNavbar from "components/Navbars/FixedTransparentNavbar.js";
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";

import Footer from "components/Footers/Footer.js";

const Account = () => {
  const authState = useSelector(state => state.authReducer); 

  const [pills, setPills] = React.useState("1");
  const [plan, setPlan] = React.useState("1");

  React.useEffect(() => {
    document.body.classList.add("signup-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("signup-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleSubscribe = (e) => {
    //set 
  };

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header header-filter" filter-color="black">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/project9.jpg") + ")"
          }}
        ></div>
        <div
          className="pricing-5 section-pricing-5 section-image"
          id="pricing-5"
          //style={{
           // backgroundImage: "url(" + require("assets/img/bg31.jpg") + ")"
          //}}
        >
          <Container>
            <Row>
              <Col md="12">
                <h2>Account Page</h2> 
              </Col>
            </Row>
            <Row>
              <Col md="5" className="offset-md-1">
                <h2 className="title">Access all features now!</h2>
                
                <p className="description">
                  <Row>
                    <Col md="6">
                      Parcel and Ownership Info<br />
                      Demographics<br />
                      Traffic Data<br />
                      Retailer Information<br />
                      Flood Map<br />
                      Wetland Info<br />
                    </Col>
                    <Col md="6">
                      Zoning Details<br />
                      Utilities<br />
                      Alcohol Sales<br />
                      Neighborhood Insights<br />
                      School Districts<br />
                      Public Transport Info<br />
                      Research Reports<br />
                      Drawing Tools, and more!<br />
                    </Col>
                  </Row>
                </p>
              </Col>
              <Col className="ml-auto mr-auto" md="5">
                <TabContent className="tab-space" activeTab={"plan" + plan}>
                  <TabPane tabId="plan1">
                    <Row>
                      <Col md="12" >
                        <Card className="card-pricing card-raised">
                          <CardBody>
                            <h5 className="category" style={{ color: 'black' }}>
                              
                            </h5>
                            <ul>
                              <li>
                                <a href={`https://app2.siteintelhub.com?token=${authState?.profile?.token}`}>
                                  <Button color="info" style={{ width: '100%', fontSize: '1.3em' }}>
                                    <i className="now-ui-icons design_app"></i>
                                    Access App
                                  </Button>
                                </a>
                              </li>
                            </ul>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default withRouter(Account);
