const moment = require('moment');

export const API_URL = process.env.REACT_APP_API_URL;

// statuses
export const ACTIVE = 'active';
export const CANCELED = 'canceled';
export const SUSPENDED = 'suspended';
export const PENDING = 'pending';

export const formatUnixTimestamp = (timestamp) => {
  const formattedDate = moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss'); // Format the date
  return formattedDate;
};
