import axios from "axios";

import * as types from "./types";
import { API_URL } from "../common/constants";

// authorization actions

export const sendEmail = async(params) => {
  try {
    const url = `${API_URL}/email/welcome`;
    const request = await axios.post(url, params);
    console.log(request);
    return request;
  } catch (error) {
    return false;
  }
};
