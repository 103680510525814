import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import TermsHeader from "components/Headers/TermsHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";

function AboutUs() {
  const [specialitySelect, setSpecialitySelect] = React.useState(null);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <TermsHeader />
        <div className="section">
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto" md="8">
                    <h2 className="title">Site Intel Hub</h2>
                    <h4 className="description">
                      These Terms of Use ("Terms") govern your access to and use of Site Intel Hub's website and services ("Service").
                      By accessing or using the Service, you agree to these Terms and all applicable laws and regulations.
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <h2>1. Acceptance of Terms</h2>
                  <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. If you do not agree with any part of the terms, you must not use this Service.</p>
                </Row>
                <Row>
                  <h2>2. Eligibility</h2>
                  <p>The Service is available to individuals aged 18 years or older. By using Site Intel Hub, you represent that you meet this age requirement.</p>
                </Row>
                <Row>
                  <h2>3. User Accounts</h2>
                  <p><strong>a. Creation and Responsibility:</strong> You may need to create an account to access certain features. Ensure your account information remains accurate and secure your password. You're responsible for all activities under your account.</p>
                  <p><strong>b. Security:</strong> You agree to notify Site Intel Hub immediately of any unauthorized use of your account.</p>
                </Row>
                <Row>
                  <h2>4. Prohibited Uses</h2>
                  <p>You agree not to use the Service:</p>
                  <ul>
                      <li>For any unlawful purpose.</li>
                      <li>To solicit others to perform or participate in unlawful acts.</li>
                      <li>To infringe upon or violate our intellectual property rights or those of others.</li>
                      <li>To engage in data mining, data harvesting, scraping, or any similar data gathering and extraction tools on any portion of the Service without express written permission from Site Intel Hub.</li>
                      <li>To submit false or misleading information.</li>
                      <li>To upload or transmit viruses or any other type of malicious code.</li>
                  </ul>
                </Row>
                <Row>
                  <h2>5. Intellectual Property</h2>
                  <p><strong>a. User Content:</strong> You retain rights to your content.</p>
                  <p><strong>b. Service Content:</strong> Except for your content, all rights to Service materials and intellectual property are owned by or licensed to Site Intel Hub.</p>
                </Row>
                <Row>
                  <h2>6. Termination</h2>
                  <p>Site Intel Hub may terminate or suspend your access for any breach of these Terms, including the prohibited uses.</p>
                </Row>
                <Row>
                  <h2>7. Amendments to Terms</h2>
                  <p>Site Intel Hub reserves the right to modify these Terms at any time. Continued use of the Service after changes constitutes your consent to the new Terms.</p>
                </Row>
                <Row>
                  <h2>8. Disclaimer of Warranties and Limitation of Liability</h2>
                  <p>The Service is provided "AS IS." Site Intel Hub disclaims all warranties, express or implied. Site Intel Hub is not liable for any damages resulting from your use of the Service.</p>
                </Row>
                <Row>
                  <h2>9. Governing Law</h2>
                  <p>These Terms are governed by the laws of the jurisdiction where Site Intel Hub operates, without regard to its conflict of law provisions.</p>
                </Row>
                <Row>
                  <h2>10. Contact Information</h2>
                  <p>For any questions about these Terms, please contact us at <a href="mailto:contact@siteintelhub.com">contact@siteintelhub.com</a>.</p>
                </Row>
                <Row>
                  <p>Thank you for using Site Intel Hub!</p>
                </Row>
              </Container>
            </div>
          </div>
          <div className="separator-line bg-info"></div>
        </div>
        <FooterBlack />
      </div>
    </>
  );
}

export default AboutUs;
