import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom"; 
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Tooltip
} from "reactstrap";
import Select from "react-select";
import GoogleLogin from 'react-google-login';

// core components
// import FixedTransparentNavbar from "components/Navbars/FixedTransparentNavbar.js";
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

import { userActions, authActions } from '../actions';

const CheckoutPage = ({ history }) => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state?.authReducer);
  const userState = useSelector((state) => state?.userReducer);
  const { user, purchase } = userState;
  
  //focus field
  const [firstNameFocus, setFirstNameFocus] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [addressFocus, setAddressFocus] = useState(false);
  const [cityFocus, setCityFocus] = useState(false);
  const [stateFocus, setStateFocus] = useState(false);
  const [zipFocus, setZipFocus] = useState(false);
  const [cardNumberFocus, setCardNumberFocus] = useState(false);
  const [cardExpMonthFocus, setCardExpMonthFocus] = useState(false);
  const [cardExpYearFocus, setCardExpYearFocus] = useState(false);
  const [cardCvvFocus, setCardCvvFocus] = useState(false);
  //name
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  //address
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [billingState, setBillingState] = useState('');
  const [zip, setZip] = useState('');
  //cc
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpMonth, setCardExpMonth] = useState('');
  const [cardExpYear, setCardExpYear] = useState('');
  const [cardCvv, setCardCvv] = useState('');
  //conditional if landingon checkoutpage without being logged in
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [termsAgreed, setTermsAgreed] = useState(false);

  const [emailError, setEmailError] = useState('');
  const [formError, setFormError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [quantity, setQuantity] = useState({ value: 1, label: "One" });

  React.useEffect(() => {
    document.body.classList.add("signup-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    setFirstName(user?.first_name || '');
    setLastName(user?.last_name || '');

    return function cleanup() {
      document.body.classList.remove("signup-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const validateEmail = (email) => {
    const isValid = /\S+@\S+\.\S+/.test(email);
    return isValid;
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    if (validateEmail(newEmail)) {
      setEmailError('');
    } else {
      setEmailError('Please enter a valid email address.');
    }
  };

  const validateFields = () => {
    //console.log('validateFields', { firstName, lastName, address, city, billingState, zip, cardNumber, cardExpMonth, cardExpYear, cardCvv, isSubmitting });
    if ((!firstName || !lastName || !address || !city || !billingState || !zip || !cardNumber || !cardExpMonth || !cardExpYear || !cardCvv)) { 
      // Show error messages or perform necessary actions
      setFormError('Please fill out all fields correctly.');

      //!firstName && setFirstNameFocus(true);
      //!lastName && setLastNameFocus(true);
      //!address && setAddressFocus(true);
      //!city && setCityFocus(true);
      //!billingState && setStateFocus(true);
      //!zip && setZipFocus(true);
      //!cardNumber && setCardNumberFocus(true);
      //!cardExpMonth && setCardExpMonthFocus(true);
      //!cardExpYear && setCardExpYearFocus(true);
      //!cardCvv && setCardCvvFocus(true);
      
      setIsSubmitting(false);
      return false;
    } else {
      setFormError('');
      return true;
    }
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    setFormError('');
    setIsSubmitting(true);
    let token = authState?.profile?.token;
    
    if (!validateFields()) {
      setIsSubmitting(false);
      return;
    }
    
    if ((!email && !user?.email) || emailError) {
      console.log('Missing inputted email or not logged in');
      setFormError('Please fill out all fields correctly.');
      setIsSubmitting(false);
      return;
    }

    if ((!password && !user?.email)) {
      setFormError('Please fill out all fields correctly.');
      setIsSubmitting(false);
      return;
    }
    
    if (!user?.id) {
      // create user, as page was accessed directly and not from signup funnel
      const userData = {
        first_name: firstName,
        last_name: lastName,
        email,
        password
      };
      const createUserSuccess = await dispatch(userActions.createUser(userData));
      console.log({ createUserSuccess });
      if (!createUserSuccess || !createUserSuccess?.success) {
        setFormError('There was an error. Please check informaion and submit again.');
        setIsSubmitting(false);
        return;
      }
      let loginData = await dispatch(authActions.login(userData));
      token = loginData?.token;
    }
    // submit transaction
    const subscribeData = {
      customer: {
        email: email || user?.email,
        description: `${firstName} ${lastName}`,
        first_name: firstName,
        last_name: lastName,
        name: `${firstName} ${lastName}`,
        address: {
          line1: address,
          city,
          state: billingState,
          postal_code: zip,
        }
      },
      card: {
        number: cardNumber,
        exp_month: cardExpMonth,
        exp_year: cardExpYear,
        cvc: cardCvv
      },
      charge: purchase
        ? {
            amount: ((purchase * quantity.value) * 100),
            rate: (purchase * 100),
            quantity: quantity.value
          }
        : null,
      recurring: purchase
        ? { // 30 days to start recurring
            startDate: moment().add(30, 'days').format('YYYY-MM-DD'),
            trialPeriodDays: 30,
            amount: ((purchase * quantity.value) * 100),
            planType: 'Monthly'
          } : { // 7 day trial, 8 days to start recurring
            startDate: moment().add(7, 'days').format('YYYY-MM-DD'),
            trialPeriodDays: 7,
            amount: 12900,
            planType: 'Monthly'
          }
    };
    
    // TEMP
    // console.log({ subscribeData });
    
    const subscribeResponse = await dispatch(userActions.subscribe(subscribeData));
    console.log({ subscribeResponse });
    if (subscribeResponse) {
      // login with updated subscription data
      // console.log('authState', token);
      await dispatch(authActions.loginToken(token));

      history.push("/thank-you");
      setIsSubmitting(false);
    } else {
      // display error message
      setFormError('There was an error. Please check informaion and submit again.');
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header header-filter" filter-color="black">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/project9.jpg") + ")"
          }}
        ></div>
        <div className="content">
          <Container>
            <Row>
              <Col md="4" className="offset-md-1">
                <h3 className="text-left">Summary</h3>
                <Card className="card-signup" style={{ color: 'black' }}>
                  <CardBody>
                    <CardTitle className="text-left" tag="h4">
                      <h4>Subscription</h4>
                    </CardTitle>
                    <CardTitle tag="h5" >
                      <Row>
                        <Col md="6" className="text-left">Monthly Plan { purchase ? <small>per seat</small> : null }</Col>
                        <Col md="6" className="text-right">{ purchase ? `$ ${purchase}` : '7-Day Trial' }</Col>
                      </Row>
                      {purchase 
                        ? <Row>
                            <Col md="6" className="text-left" style={{ marginTop: '10px' }}>Quantity</Col>
                            <Col md="6">
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                name=""
                                onChange={(option) => {
                                  console.log('quantity', option);
                                  setQuantity(option);
                                }}
                                options={[
                                  { value: 1, label: "One" },
                                  { value: 2, label: "Two" },
                                  { value: 3, label: "Three" },
                                  { value: 4, label: "Four" },
                                  { value: 5, label: "Five" },
                                  { value: 6, label: "Six" },
                                  { value: 7, label: "Seven" },
                                  { value: 8, label: "Eight" },
                                  { value: 9, label: "Nine" },
                                  { value: 10, label: "Ten" }
                                ]}
                                placeholder="One"
                                value={quantity}
                                title="Number of seats"
                              ></Select>
                            </Col>
                          </Row>
                        : null}                      
                    </CardTitle>
                    <hr />
                    <CardTitle tag="h5" className="text-center">
                      {purchase
                        ? <Row>
                            <Col md="8" className="text-left"><strong>Total Amount:</strong></Col>
                            <Col md="4" className="text-right">
                              <strong>$ {purchase * quantity.value}</strong>
                            </Col>
                          </Row>
                        : <Row><Col md="12" tag="small">Your card will be charged $129/month after the 7-day trial unless cancelled beforehand.</Col></Row>
                      }
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col className="mr-auto" md="6">
                <Card className="card-signup">
                  <CardBody>
                    <CardTitle className="text-center" tag="h4">
                      <h4>Checkout</h4>
                    </CardTitle>
                    <div className="social text-left" style={{ color: 'black' }}>
                      Billing Contact
                    </div>
                    <Form action="" className="form" method="post" onSubmit={handleSubmit}>
                      <Row>
                        <Col md="6">
                          <InputGroup
                            className={firstNameFocus ? "input-group-focus" : ""}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons users_circle-08"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              autoComplete="given-name"
                              placeholder="First Name"
                              type="text"
                              onChange={(event) => { setFirstName(event.target.value); }}
                              onFocus={() => setFirstNameFocus(true)}
                              onBlur={() => setFirstNameFocus(false)}
                              value={firstName}
                            ></Input>
                          </InputGroup>
                        </Col>
                        <Col md="6">
                          <InputGroup
                            className={lastNameFocus ? "input-group-focus" : ""}
                          >
                            <Input
                              autoComplete="family-name"
                              placeholder="Last Name"
                              type="text"
                              onChange={(event) => { setLastName(event.target.value); }}
                              onFocus={() => setLastNameFocus(true)}
                              onBlur={() => setLastNameFocus(false)}
                              value={lastName}
                            ></Input>
                          </InputGroup>
                        </Col>
                      </Row>
                      {(!user?.id) && (
                        <>
                          <InputGroup
                            className={emailFocus ? "input-group-focus" : ""}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons ui-1_email-85"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              autoComplete="email"
                              placeholder="Your Email..."
                              type="text"
                              onChange={handleEmailChange}
                              onFocus={() => setEmailFocus(true)}
                              onBlur={() => setEmailFocus(false)}
                            ></Input>
                            {emailError && <span className="text-danger">{emailError}</span>}
                          </InputGroup>
                          <InputGroup
                            className={passwordFocus ? "input-group-focus" : ""}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons ui-1_lock-circle-open"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              autoComplete="password"
                              placeholder="Password"
                              type="password"
                              onChange={(event) => { setPassword(event.target.value); }}
                              onFocus={() => setPasswordFocus(true)}
                              onBlur={() => setPasswordFocus(false)}
                            ></Input>
                          </InputGroup>
                        </>
                      )}

                      <InputGroup
                        className={addressFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons location_pin"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoComplete="address"
                          placeholder="Address..."
                          type="text"
                          onChange={(event) => { setAddress(event.target.value); }}
                          onFocus={() => setAddressFocus(true)}
                          onBlur={() => setAddressFocus(false)}
                        ></Input>
                      </InputGroup>
                      <Row>
                        <Col md="5">
                          <InputGroup
                            className={cityFocus ? "input-group-focus" : ""}
                          >
                            <Input
                              autoComplete="address-level2"
                              placeholder="City"
                              type="text"
                              onChange={(event) => { setCity(event.target.value); }}
                              onFocus={() => setCityFocus(true)}
                              onBlur={() => setCityFocus(false)}
                            ></Input>
                          </InputGroup>
                        </Col>
                        <Col md="3">
                          <InputGroup
                            className={stateFocus ? "input-group-focus" : ""}
                          >
                            <Input
                              autoComplete="address-level1"
                              placeholder="State"
                              type="text"
                              onChange={(event) => { setBillingState(event.target.value); }}
                              onFocus={() => setStateFocus(true)}
                              onBlur={() => setStateFocus(false)}
                            ></Input>
                          </InputGroup>
                        </Col>
                        <Col md="4">
                          <InputGroup
                            className={zipFocus ? "input-group-focus" : ""}
                          >
                            <Input
                              autoComplete="postal-code"
                              placeholder="Postal/Zip"
                              type="text"
                              onChange={(event) => { setZip(event.target.value); }}
                              onFocus={() => setZipFocus(true)}
                              onBlur={() => setZipFocus(false)}
                            ></Input>
                          </InputGroup>
                        </Col>
                      </Row>

                      <div className="social text-left" style={{ color: 'black' }}>
                        Payment Information
                      </div>
                      <InputGroup
                        className={cardNumberFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons location_pin"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoComplete="cc-number"   
                          placeholder="Credit card"
                          type="number"
                          onChange={(event) => { setCardNumber(event.target.value); }}
                          onFocus={() => setCardNumberFocus(true)}
                          onBlur={() => setCardNumberFocus(false)}
                        ></Input>
                      </InputGroup>
                      <Row>
                        <Col md="4">
                          <InputGroup
                            className={cardExpMonthFocus ? "input-group-focus" : ""}
                          >
                            <Input
                              autoComplete="cc-exp-month"  
                              placeholder="MM"
                              type="number"
                              onChange={(event) => { setCardExpMonth(event.target.value); }}
                              onFocus={() => setCardExpMonthFocus(true)}
                              onBlur={() => setCardExpMonthFocus(false)}
                            ></Input>
                          </InputGroup>
                        </Col>
                        <Col md="4">
                          <InputGroup
                            className={cardExpYearFocus ? "input-group-focus" : ""}
                          >
                            <Input    
                              autoComplete="cc-exp-year"  
                              placeholder="YY"
                              type="number"
                              onChange={(event) => { setCardExpYear(event.target.value); }}
                              onFocus={() => setCardExpYearFocus(true)}
                              onBlur={() => setCardExpYearFocus(false)}
                            ></Input>
                          </InputGroup>
                        </Col>
                        <Col md="4">
                          <InputGroup
                            className={cardCvvFocus ? "input-group-focus" : ""}
                          >
                            <Input
                              autoComplete="cc-csc"  
                              placeholder="CVV"
                              type="number"
                              onChange={(event) => { setCardCvv(event.target.value); }}
                              onFocus={() => setCardCvvFocus(true)}
                              onBlur={() => setCardCvvFocus(false)}
                            ></Input>
                          </InputGroup>
                        </Col>
                        { /*
                        <Col md="3">
                          <InputGroup
                            className={zipFocus ? "input-group-focus" : ""}
                          >
                            <Input
                              placeholder="Postal/Zip"
                              type="text"
                              onChange={(event) => setZip(event.target.value)}
                              onFocus={() => setZipFocus(true)}
                              onBlur={() => setZipFocus(false)}
                            ></Input>
                          </InputGroup>
                        </Col>
                        */ }
                      </Row>
                      {formError && <Row><Col sm="12"><div className="text-danger">{formError}</div></Col></Row>}
                      
                      <CardFooter className="text-center">
                        <Button
                          className="btn-round"
                          color="info"
                          size="lg"
                          type="submit"
                          disabled={ !!isSubmitting}
                        >
                          { isSubmitting && <i className="now-ui-icons loader_refresh spin"></i> }
                          Submit
                        </Button>
                      </CardFooter>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default withRouter(CheckoutPage);
