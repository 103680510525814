import React from "react";
import { withRouter, Link } from "react-router-dom"; 
import { useDispatch } from 'react-redux';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Tooltip
} from "reactstrap";
import GoogleLogin from 'react-google-login';

// core components
// import FixedTransparentNavbar from "components/Navbars/FixedTransparentNavbar.js";
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

import { authActions, userActions } from '../actions';

function GoogleLoginButton({ id }) {
  const responseGoogle = (response) => {
    // Handle the Google login response here
    // const profile = response.getBasicProfile();
    console.log('response', response);
  };

  return (
    <GoogleLogin
      clientId="510601587418-561f9n729ob5l27s1rgil3ootdc5t3qr.apps.googleusercontent.com"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={'single_host_origin'}
      render={(renderProps) => (
        <Button
          id={id}
          className="btn-icon btn-round mr-2"
          color="google"
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <i className="fab fa-google"></i>
        </Button>
      )}
    />
  );
}

const SignupPage = ({ history }) => {
  const dispatch = useDispatch();

  const [fullFocus, setFullFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [fullName, setFullName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [termsAgreed, setTermsAgreed] = React.useState(false);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);

  const [emailError, setEmailError] = React.useState('');
  const [formError, setFormError] = React.useState('');

  React.useEffect(() => {
    document.body.classList.add("signup-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("signup-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const validateEmail = (email) => {
    const isValid = /\S+@\S+\.\S+/.test(email);
    return isValid;
  };

  const validateFields = () => {
    if (!fullName || !email || !password || !termsAgreed || emailError) {
      // Show error messages or perform necessary actions
      console.log('Please fill out all fields correctly.');
      setFormError('Please fill out all fields correctly.');
      return false;
    } else {
      setFormError('');
      return true;
    }
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    if (formError === "User already exists.") {
      setFormError('');
    }

    if (validateEmail(newEmail)) {
      setEmailError('');
    } else {
      setEmailError('Please enter a valid email address.');
    }
  };

  const handleSubmit = async(event) => {
    // console.log('handleSubmit');
    // console.log('***', fullName, email, password, termsAgreed, emailError, '***');
    event.preventDefault();

    setHasSubmitted(true);

    if (!validateFields()) {
      return;
    }
    
    const userData = {
      fullName, email, password
    };

    const createUserResponse = await dispatch(userActions.createUser(userData));
    //console.log('createUserResponse', createUserResponse);
    setFormError('');
    if (createUserResponse.success) {
      // Continue with registration process
      console.log('Registration successful.');
      history.push("/pricing");
    } else {
      //could not register
      console.log('Error registering user' + createUserResponse.error);
      setFormError(createUserResponse?.error || 'Error registering user');
    }
  };

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header header-filter" filter-color="black">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/project9.jpg") + ")"
          }}
        ></div>
        <div className="content">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="6" lg="4">
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons media-2_sound-wave"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">Real Estate Possibilities</h5>
                    <p className="description">
                      Discover a realm of real estate opportunities at Site Intel Hub
                      with comprehensive property listings & data visualization.
                      Make confident property decisions.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons media-1_button-pause"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">Make Informed Choices</h5>
                    <p className="description">
                      Navigate the property market with precision.
                      Join to access detailed property data, market trends, and localized insights.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons users_single-02"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">Elevate Your Expertise</h5>
                    <p className="description">
                      Rise above in the real estate world with Site Intel Hub.
                      Join today to empower your real estate journey.
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="mr-auto" md="6" lg="4">
                <Card className="card-signup">
                  <CardBody>
                    <CardTitle className="text-center" tag="h4">
                      Register
                    </CardTitle>
                    {/*
                    <div className="social text-center">
                      <Tooltip placement="top" target="googleButton">
                        Register with Google
                      </Tooltip>
                      <GoogleLoginButton id="googleButton" />
                      <h5 className="card-description">or register with email </h5>
                    </div>
                    */}
                    <Form action="" className="form" method="post" onSubmit={handleSubmit}>
                      <InputGroup
                        className={fullFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i
                              className="now-ui-icons users_circle-08"
                              style={{ color: (hasSubmitted && !fullName) ? "red" : null }}
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoComplete="name"
                          placeholder="Full Name..."
                          type="text"
                          onChange={(event) => setFullName(event.target.value)}
                          onFocus={() => setFullFocus(true)}
                          onBlur={() => setFullFocus(false)}
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={emailFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i
                              className="now-ui-icons ui-1_email-85"
                              style={{ color: (hasSubmitted && !email) ? "red" : null }}
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoComplete="email"
                          placeholder="Your Email..."
                          type="text"
                          onChange={handleEmailChange}
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                        ></Input>
                        {emailError && <span className="text-danger">{emailError}</span>}
                      </InputGroup>
                      <InputGroup
                        className={passwordFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i
                              className="now-ui-icons ui-1_lock-circle-open"
                              style={{ color: (hasSubmitted && !password) ? "red" : null }}
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoComplete="password"
                          placeholder="Password"
                          type="password"
                          onChange={(event) => setPassword(event.target.value)}
                          onFocus={() => setPasswordFocus(true)}
                          onBlur={() => setPasswordFocus(false)}
                        ></Input>
                      </InputGroup>
                      <FormGroup check>
                        <Label check style={{ border: (hasSubmitted && !termsAgreed) ? "1px solid red" : "" }}>
                          <Input type="checkbox" onChange={() => {
                            setTermsAgreed(!termsAgreed);
                          }}></Input>
                          <span className="form-check-sign"></span>I agree to
                          the terms and{" "}
                          <a href="#terms" onClick={(e) => e.preventDefault()}>
                            conditions
                          </a>
                          .
                        </Label>
                      </FormGroup>
                      {
                        formError &&
                        <div className="text-danger">{formError}</div>
                      }
                      <CardFooter className="text-center">
                        <Button
                          className="btn-round"
                          color="info"
                          size="lg"
                          type="submit"
                        >
                          Get Started
                        </Button>
                      </CardFooter>
                    </Form>
                  </CardBody>
                </Card>
                Already registerd?  <Link to={"/login-page"}>Login here</Link>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default withRouter(SignupPage);
