import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';

import { store } from './store/store';
import { getListings } from './actions/listingActions';

// styles
import 'assets/css/bootstrap.min.css';
import 'assets/scss/now-ui-kit.scss?v=1.5.0';
import 'assets/demo/demo.css?v=1.5.0';
import 'assets/demo/react-demo.css?v=1.5.0';
import 'assets/demo/nucleo-icons-page-styles.css?v=1.5.0';
// pages
import BlogPost from 'views/examples/BlogPost.js';
import BlogPosts from 'views/examples/BlogPosts.js';
import ContactUs from 'views/examples/ContactUs.js';
import Ecommerce from 'views/examples/Ecommerce.js';
import Index from 'views/Index.js';
import LandingPage from 'views/examples/LandingPage.js';
import LoginPage from 'views/LoginPage.js';
import NucleoIcons from 'views/NucleoIcons.js';
import Presentation from 'views/Presentation.js';
import ProductPage from 'views/examples/ProductPage.js';
import ProfilePage from 'views/examples/ProfilePage.js';
import Sections from 'views/Sections.js';

import AboutUs from 'views/AboutUs.js';
import Terms from 'views/Terms.js';

//Signup Subscribe Pricing Checkout
import SignupPage from 'views/SignupPage.js';
import PricingPage from 'views/PricingPage.js';
import CheckoutPage from 'views/CheckoutPage.js';
import ThankYouPage from 'views/ThankYouPage.js';
import Account from 'views/Account.js';

const AppWrapper = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state?.userReducer);
  const authState = useSelector((state) => state?.authReducer);

  //useEffect(() => {
    
  //}, [dispatch]);

  const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        authState.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login-page" />
        )
      }
    />
  );

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
        <Route path="/blog-post" render={(props) => <BlogPost {...props} />} />
        <Route path="/blog-posts" render={(props) => <BlogPosts {...props} />} />
        <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
        <Route path="/e-commerce" render={(props) => <Ecommerce {...props} />} />
        <Route path="/index" render={(props) => <Index {...props} />} />
        <Route
          path="/landing-page"
          render={(props) => <LandingPage {...props} />}
        />
        <Route path="/login-page" render={(props) => <LoginPage {...props} />} />
        <Route
          path="/nucleo-icons"
          render={(props) => <NucleoIcons {...props} />}
        />
        <Route
          path="/presentation"
          render={(props) => <Presentation {...props} />}
        />
        <Route
          path="/product-page"
          render={(props) => <ProductPage {...props} />}
        />
        <Route
          path="/profile-page"
          render={(props) => <ProfilePage {...props} />}
        />
        <Route path="/terms" render={(props) => <Terms {...props} />} />
        <Route path="/sections" render={(props) => <Sections {...props} />} />
        <Route path="/sign-up" render={(props) => <SignupPage {...props} />} />
        <Route path="/pricing" render={(props) => <PricingPage {...props} />} />
        <Route path="/checkout" render={(props) => <CheckoutPage {...props} />} />
        <Route path="/thank-you" render={(props) => <ThankYouPage {...props} />} />
        {/*<Route path="/account" render={(props) => <Account {...props} />} />*/}
        <ProtectedRoute path="/account" component={Account} />
        <Route
          path="/"
          render={(props) => <Presentation {...props} />}
        />
        <Redirect to="/presentation" />
      </Switch>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <AppWrapper />
  </Provider>
);
