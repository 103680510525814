import React, { useState, useEffect } from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import AboutUsHeader from "components/Headers/AboutUsHeader.js";
import Footer from "components/Footers/Footer.js";
import SignupRow from "components/Index/SignupRow.js";

import { userActions, emailActions } from '../actions';

const AboutUs = () => {
  const dispatch = useDispatch();
  const userDesignations = useSelector(state => state.userReducer?.designations); 

  const [designationSelect, setDesignationSelect] = useState(null);
  const [firstFocus, setFirstFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [formError, setFormError] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    dispatch(userActions.getUserDesignations());

    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const validateEmail = (email) => {
    const isValid = /\S+@\S+\.\S+/.test(email);
    return isValid;
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    if (validateEmail(newEmail)) {
      setEmailError('');
    } else {
      setEmailError('Please enter a valid email address.');
    }
  };

  const validateFields = () => {
    if (!fullName || !email || emailError) {
      // Show error messages or perform necessary actions
      console.log('Please fill out all fields correctly.');
      setFormError('Please fill out all fields correctly.');
      return false;
    } else {
      setFormError('');
      return true;
    }
  };

  const handleSubmit = async(event) => {
    // console.log('handleSubmit');
    // console.log('***', fullName, email, password, termsAgreed, emailError, '***');
    event.preventDefault();

    setHasSubmitted(true);

    if (!validateFields()) {
      return;
    }
    
    const userData = {
      fullName, email, designation_id: designationSelect.value
    };

    // attempt to register user with the provided data
    // const createUserResponse = await dispatch(userActions.createUser(userData));
    // console.log('createUserResponse', createUserResponse);
    
    const emailResponse = await emailActions.sendEmail({ name: fullName, email, designation: designationSelect.label });
    console.log('emailResponse', emailResponse?.data?.success, emailResponse?.data?.message);
    setFormError(emailResponse?.data?.message);
  };
  
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <AboutUsHeader />
        <div className="section">
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto" md="8">
                    <h2 className="title">Our Mission</h2>
                    <h4 className="description">
                      Our mission is to transform the commercial real estate industry
                      by providing a single, comprehensive platform that delivers
                      unparalleled data visualization, analysis, and collaboration
                      tools. We strive to make complex data easily accessible and
                      actionable for professionals across the industry.
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-success icon-circle">
                        <i className="now-ui-icons objects_globe"></i>
                      </div>
                      <h4 className="info-title">Data Integration</h4>
                      <p className="description">
                        Seamlessly layer data from local, state, and national sources on a single platform for comprehensive visual analysis.
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-info icon-circle">
                        <i className="now-ui-icons ui-1_zoom-bold"></i>
                      </div>
                      <h4 className="info-title">Advanced Search</h4>
                      <p className="description">
                        Utilize dynamic search capabilities to efficiently find parcels, traffic data, and demographic insights.
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-primary icon-circle">
                        <i className="now-ui-icons design_vector"></i>
                      </div>
                      <h4 className="info-title">Custom Reporting</h4>
                      <p className="description">
                        Generate customizable, company-branded reports to visualize and present data in a professional format.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-warning icon-circle">
                        <i className="now-ui-icons location_pin"></i>
                      </div>
                      <h4 className="info-title">Multiple Listing Service</h4>
                      <p className="description">
                        Post your opportunities and access a broad range of options and find the perfect property that meets your requirements.
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-danger icon-circle">
                        <i className="now-ui-icons ui-1_zoom-bold"></i>
                      </div>
                      <h4 className="info-title">Prospect and Analysis</h4>
                      <p className="description">
                        Streamline prospecting with our robust web application, integrating many datasets from national to local sources, all designed to enhance your land analysis and decision-making process.
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-primary icon-circle">
                        <i className="now-ui-icons tech_mobile"></i>
                      </div>
                      <h4 className="info-title">Data Visualization</h4>
                      <p className="description">
                        Elevate your business strategy by transforming essential data into intuitive visual representations on our GIS mapping tool, enabling clearer insights and informed decisions.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-warning icon-circle">
                        <i className="now-ui-icons business_chart-pie-36"></i>
                      </div>
                      <h4 className="info-title">Collaborative Mapping</h4>
                      <p className="description">
                        Share interactive maps and insights with colleagues to foster collaboration and informed decision-making.
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-danger icon-circle">
                        <i className="now-ui-icons objects_spaceship"></i>
                      </div>
                      <h4 className="info-title">Innovative Solutions</h4>
                      <p className="description">
                        Leverage cutting-edge technology to access real-time data and analytics, driving strategic commercial real estate decisions.
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-primary icon-circle">
                        <i className="now-ui-icons tech_mobile"></i>
                      </div>
                      <h4 className="info-title">Mobile Access</h4>
                      <p className="description">
                        Access critical data and tools on the go with our mobile-optimized platform, ensuring you're always connected.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>

          <SignupRow />

          <div className="about-contact">
            <Container>
              <Row>
                <Col className="mr-auto ml-auto" md="8">
                  <h2 className="text-center title">Discover More About Our Services</h2>
                  <h4 className="text-center description">
                    Interested in learning more about what we offer? Explore the
                    various aspects of our service in detail. Share your inquiries
                    or specific interests with us, and let's initiate a conversation
                    to tailor our solutions to your needs. Reach out for more information.
                  </h4>
                  <Form className="contact-form" onSubmit={handleSubmit}>
                    <Row>
                      <Col md="4">
                        <label>Name</label>
                        <InputGroup
                          className={firstFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons users_circle-08"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            autoComplete="fullname"
                            placeholder="Full Name..."
                            type="text"
                            onChange={(event) => setFullName(event.target.value)}
                            onFocus={() => setFirstFocus(true)}
                            onBlur={() => setFirstFocus(false)}
                          ></Input>
                        </InputGroup>
                      </Col>
                      <Col md="4">
                        <label>Your email</label>
                        <InputGroup
                          className={emailFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_email-85"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            autoComplete="email"
                            placeholder="Your Email..."
                            type="text"
                            onChange={handleEmailChange}
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                          ></Input>
                        </InputGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label className="control-label">Designation</label>
                          <Select
                            className="react-select react-select-primary"
                            onChange={(value) => setDesignationSelect(value)}
                            classNamePrefix="react-select"
                            placeholder="Designation"
                            value={designationSelect}
                            name=""
                            options={userDesignations?.map(designation => ({
                              value: designation.id, // Assuming you want the `id` to be the `value`
                              label: designation.designation // Mapping `designation` field to `label`
                            }))}
                          ></Select>
                        </FormGroup>
                      </Col>
                      {
                        formError &&
                        <div className="text-danger">{formError}</div>
                      }
                    </Row>
                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="4">
                        <Button
                          className="btn-round mt-4"
                          color="info"
                          size="lg"
                          type="submit"
                        >
                          Learn More
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
