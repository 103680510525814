import React, { useEffect, useState } from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
import { useSelector, useDispatch } from 'react-redux';

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import PresentationHeader from "components/Headers/PresentationHeader.js";
import Footer from "components/Footers/Footer.js";

// sections for this page
import Components from "./presentation-sections/Components.js";
import Cards from "./presentation-sections/Cards.js";
import Content from "./presentation-sections/Content.js";
import Sections from "./presentation-sections/Sections.js";
import Examples from "./presentation-sections/Examples.js";
import FreeDemo from "./presentation-sections/FreeDemo.js";
import Icons from "./presentation-sections/Icons.js";
import Image from "./presentation-sections/Image.js";
import Testimonials from "./presentation-sections/Testimonials.js";
import Pricing from "./presentation-sections/Pricing.js";
import Blurb from "../components/Index/Blurb";
import Images from "../components/Index/Images";

import Listings from "../components/Listings";
import SignupRow from "../components/Index/SignupRow.js";

import { listingActions } from '../actions';

const Presentation = () => {
  const dispatch = useDispatch();

  const listingState = useSelector(state => state.listingReducer);

  useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("presentation-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });

  useEffect(() => { 
    // GET listings
    dispatch(listingActions.getListings());
  }, []);
  
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <PresentationHeader />
        <div className="main">
          <Images />
          <Blurb />
          {/*}
          <Components />
          <Cards />
          <Content />
          <Sections />
          <Examples />
          <FreeDemo />
          <Icons />
          <Image />
          <Testimonials />
          <Pricing />
          */}
          <Listings listings={listingState.listing} />
          <SignupRow />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Presentation;
