/*eslint-disable*/
import React from "react";
import { Link } from 'react-router-dom';

// reactstrap components
import { Container } from "reactstrap";

// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer" data-background-color="black">
        <Container>
          <nav>
            <ul>
              <li>
                <Link to="/terms">
                  Terms
                </Link>
              </li>
              <li>
                <Link to="/about-us">
                  About Us
                </Link>
              </li>
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Site Intel Hub</div>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
