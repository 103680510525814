import jwtDecode from 'jwt-decode';
import {
  LOGIN,
  VALIDATE,
  CHECK_TOKEN,
  LOGOUT,
  CREATE_USER,
  CREATE_SUBSCRIPTION,
} from '../actions/types';
//import { cookies } from '../sessions/public';

const INITIAL_STATE = {
  status: 0,
  profile: {},
  authenticated: false,
  error: null
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VALIDATE: {
      if (action.payload.status === 200) {
        const { token, email } = action.payload.data;
        //cookies.createCookie('token', token, 7);
        const { salt, sessionId } = (token) ? jwtDecode(token) : {};
        console.log({ salt, sessionId });
        return {
          ...state,
          login: action.payload.data.message
        };
      }

      return { ...state, message: 'Network error' };
    }
    case CHECK_TOKEN: {
      const { payload = {} } = action;
      const { profile } = payload.message || {};
      // console.log(data);
      if (payload?.authenticated) {
        return { ...state, status: 1, profile, };
      }
      return state;
    }
    case LOGOUT: {
      //cookies.deleteCookie('token', '/', '.siteintelhub.com');
      return { ...state, status: 0, profile: {}, authenticated: false, error: null };
    }
    case CREATE_USER: // fire login when new user created
    case LOGIN: {
      // console.log('authReducer', action.payload);
      // action.payload.setDataValue('status',0);
      if (action.payload.success === true) {
        const token = action.payload.token;
        const tokenDecoded = (token) ? jwtDecode(token) : {};
        //console.log('authReducer return', {
        //...state,
        //status: 1,
        //profile: { ...tokenDecoded, token },
        //authenticated: true,
        //error: null,
        //});
        return {
          ...state,
          status: 1,
          profile: { ...tokenDecoded, token },
          authenticated: true,
          error: null,
        };
      } else if (action.payload.success === false) {
        if (action.payload.message === 'Authentication error') {
          return {
            ...state,
            status: 1,
            profile: {},
            error: 'Authentication error'
          };
        } else if (action.payload.message === 'User not found' || action.payload.message === 'Invalid password') {
          return {
            ...state,
            status: 1,
            profile: {},
            error: 'Check Username or Password'
          };
        }
      }
      return { ...state, message: action.payload.message };
    }
    default:
      return state;
  }
};

export default authReducer;
