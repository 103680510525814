import {
  GET_LISTINGS,
  CREATE_LISTING,
  UPDATE_LISTING
} from '../actions/types';

const INITIAL_STATE = {
  listings: [],
};

const listingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_LISTINGS':
      const listings = action.payload;
      return { ...state, listings };
    default:
      return state;
  }
};

export default listingReducer;
