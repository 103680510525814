import axios from "axios";

import * as types from "./types";
import { cookies } from "../utils/cookieUtils";
import { API_URL } from "../common/constants";

// authorization actions

export const login = (loginData) => {
  const { email, password } = loginData;
  return async (dispatch) => {
    try {
      const url = `${API_URL}/auth/login`;
      const request = await axios.post(url, { email, password });
      dispatch({
        type: types.LOGIN,
        payload: request?.data
      });
      return request?.data;
    } catch (error) {
      return false;
    }
  };
};

export const logingoogle = (idtoken) => {
  const props = {
    idtoken
  };
  const url = `${API_URL}/auth/logingoogleuser`;
  const request = axios.post(url, props);
  return {
    type: types.LOGIN,
    payload: request
  };
};

export const loginmicrosoft = (email, appid) => {
  const props = {
    email
  };
  const url = `${API_URL}/auth/loginmicrosoftuser`;
  const request = axios.post(url, props);
  return {
    type: types.LOGIN,
    payload: request
  };
};

export const setCompany = (email, status) => {
  const request = {
    email,
    status
  };
  console.log("setCompany", request);
  return {
    type: types.SET_COMPANY,
    payload: request
  };
};

export const checkToken = () => {
  const token = cookies.readCookie("token");
  const url = `${API_URL}/auth/valid/${token}`;
  const request = axios.get(url);
  return {
    type: types.CHECK_TOKEN,
    payload: request?.data
  };
};

export const loginToken = (token) => {
  return async (dispatch) => {
    try {
      const url = `${API_URL}/auth/login/token`;
      const request = await axios.post(url, { token });
      console.log("loginToken", request);
      dispatch({
        type: types.LOGIN,
        payload: request?.data
      });
    } catch (error) {
      return false;
    }
  };
};

export const logout = () => {
  return {
    type: types.LOGOUT
  };
};
