import {
  CREATE_USER,
  UPDATE_USER,
  GET_USERS,
  CREATE_BILLING,
  UPDATE_BILLING,
  GET_BILLINGS,
  CREATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  GET_SUBSCRIPTIONS
} from '../actions/types';
//import { cookies } from '../sessions/public';

const INITIAL_STATE = {
  status: 0,
  loading: false,
  user: null
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FETCH_USER_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_USER_SUCCESS':
      return { ...state, loading: false, user: action.payload };
    case 'FETCH_USER_FAILURE':
      return { ...state, loading: false, error: action.payload };
    case 'SET_ADDITIONAL_DATA':
      return { ...state, additionalData: action.payload };
    case 'UPDATE_ANOTHER_PROPERTY':
      return { ...state, anotherProperty: 'new value' };
    case 'CREATE_USER':
      return { ...state, user: action.payload.user };
    case 'EDIT_USER':
      return { ...state, user: action.payload };
    case 'DELETE_USER':
      return { ...state, user: action.payload };
    case 'CREATE_BILLING':
      return { ...state, user: { ...state.user, billing: action.payload } };
    case 'UPDATE_BILLING':
      return { ...state };
    case 'GET_BILLINGS':
      return { ...state };
    case 'CREATE_SUBSCRIPTION':
      return {
        ...state,
        user: {
          ...state.user,
          subscription: action.payload
        }
      };
    case 'UPDATE_SUBSCRIPTION':
      return { ...state };
    case 'GET_SUBSCRIPTIONS':
      return { ...state };
    case 'LOGIN':
      return {
        ...state,
        user: action.payload?.user
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null
      };
    case 'GET_USER_DESIGNATIONS':
      return {
        ...state,
        designations: action.payload
      };
    case 'USER_PURCHASE':
      return {
        ...state,
        purchase: action.payload //amount 129 || 0 (7 day trial)
      };
    default:
      return state;
  }
};

export default userReducer;
