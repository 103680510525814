import React from "react";
import { withRouter, Link, useLocation } from "react-router-dom"; 
import { useDispatch } from 'react-redux';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Tooltip
} from "reactstrap";
import GoogleLogin from 'react-google-login';

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

import { userActions } from '../actions';

const PricingPage = ({ history }) => {
  const dispatch = useDispatch();

  const [pills, setPills] = React.useState("1");
  const [plan, setPlan] = React.useState("1");
  const location = useLocation(); // Access location object
  const searchParams = new URLSearchParams(location.search); // Parse query string
  const getTrial = searchParams.get("getTrial") === "yes"; // Check if trial=yes in the query string

  React.useEffect(() => {
    document.body.classList.add("signup-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("signup-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleSubscribe = (value) => {
    //console.log(value);
    dispatch(userActions.userPurchase(value)); 
    history.push("/checkout");
  };

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header header-filter" filter-color="black">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/project9.jpg") + ")"
          }}
        ></div>
        <div
          className="pricing-5 section-pricing-5 section-image"
          id="pricing-5"
          //style={{
           // backgroundImage: "url(" + require("assets/img/bg31.jpg") + ")"
          //}}
        >
          <Container>
            <Row>
              <Col md="12">
                <h2>Subscribe Now!</h2> 
              </Col>
            </Row>
            <Row>
              <Col md="5" className="offset-md-1">
                <h2 className="title">Unlock the power of our application today!</h2>
                
                <p className="description">
                  <b>Upgrade to monthly plan</b>
                  <Row>
                    <Col md="6">
                      Parcel and Ownership Info<br />
                      Demographics<br />
                      Traffic Data<br />
                      Retailer Information<br />
                      Radius Rings<br />
                      Flood Map<br />
                      Wetland Info<br />
                    </Col>
                    <Col md="6">
                      Zoning Details<br />
                      Utilities<br />
                      Alcohol Sales<br />
                      Neighborhood Insights<br />
                      School Districts<br />
                      Public Transport Info<br />
                      Research Reports<br />
                      Drawing Tools, and more!<br />
                    </Col>
                  </Row>
                </p>
              </Col>
              <Col className="ml-auto mr-auto" md="5">
                <TabContent className="tab-space" activeTab={"plan" + plan}>
                  <TabPane tabId="plan1">
                    <Row>
                      <Col md={ (getTrial) ? '6' : '12' } >
                        <Card className="card-pricing card-raised">
                          <CardBody>
                            <h5 className="category" style={{ color: 'black' }}>Monthly</h5>
                            <CardTitle tag="h1" style={{ color: 'black' }}>
                              <small>$</small>
                              129
                            </CardTitle>
                            <ul>
                              <li>
                                <b>Upload</b> Listings
                              </li>
                              <li>
                                <b>Featured</b> Listings
                              </li>
                              <li>
                                <b>Lead</b> Generation
                              </li>
                              <li>
                                <b>Full</b> Access to App
                              </li>
                            </ul>
                            <Button
                              className="btn-round"
                              color="info"
                              //href="#checkout"
                              //tag={Link} to="/checkout"
                              onClick={() => handleSubscribe(129)}
                            >
                              Subscribe!
                            </Button>
                          </CardBody>
                        </Card>
                        { /*Not yet ready to subscribe? <Link to="/account">Continue</Link> to account.*/}
                      </Col>
                      <Col md="6" style={{ display: (getTrial) ? '' : 'none' }}>
                        <Card className="card-pricing card-plain">
                          <CardBody>
                            <h5 className="category">7-day Trial</h5>
                            <CardTitle tag="h1">
                              <small></small>
                              &nbsp;
                            </CardTitle>
                            <ul>
                              <li>
                                Not yet ready to subscribe?
                              </li>
                              <li>
                                Access for a week
                              </li>
                              <li>
                                &nbsp;
                              </li>
                              <li>
                                <b>Full</b> Access to App
                              </li>
                            </ul>
                            <Button
                              className="btn-round"
                              color="default"
                              //href="#trial"
                              onClick={() => handleSubscribe(0)}
                            >
                              Start Trial
                            </Button>
                          </CardBody>
                        </Card>
                        
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="plan2">
                    <Row>
                      <Col md="6">
                        <Card className="card-pricing card-raised">
                          <CardBody>
                            <h5 className="category">Standard</h5>
                            <CardTitle tag="h1">
                              <small>$</small>
                              20
                            </CardTitle>
                            <ul>
                              <li>
                                <b>5</b> Projects
                              </li>
                              <li>
                                <b>2GB</b> Storage
                              </li>
                              <li>
                                <b>Unlimited</b> users
                              </li>
                              <li>
                                <b>No time</b> tracking
                              </li>
                            </ul>
                            <Button
                              className="btn-round"
                              color="info"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Subscribe!
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="6">
                        <Card className="card-pricing card-plain">
                          <CardBody>
                            <h5 className="category">Premium</h5>
                            <CardTitle tag="h1">
                              <small>$</small>
                              60
                            </CardTitle>
                            <ul>
                              <li>
                                <b>7</b> Projects
                              </li>
                              <li>
                                <b>3GB</b> Storage
                              </li>
                              <li>
                                <b>Unlimited</b> users
                              </li>
                              <li>
                                <b>No time</b> tracking
                              </li>
                            </ul>
                            <Button
                              className="btn-round"
                              color="info"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Subscribe!
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default withRouter(PricingPage);
