import React, { useState, useEffect } from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

const SignupRow = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="px-0" md="6">
            <Card
              className="card-fashion card-background"
              style={{
                backgroundImage:
                  "url(" + require("assets/img/banners/skyline-siteintelhub.png") + ")"
              }}
            >
              <CardBody>
                <CardTitle className="text-left" tag="div">
                  <h2>
                    <a href="#signup" onClick={(e) => e.preventDefault()}>
                      Your solution for commercial real estate needs.
                    </a>
                  </h2>
                </CardTitle>
                { /*
                <CardFooter className="text-left">
                  <div className="stats">
                    <span>
                      <i className="now-ui-icons users_circle-08"></i>
                      Nicholas Deleon
                    </span>
                    <span>
                      <i className="now-ui-icons tech_watch-time"></i>
                      June 2, 2021
                    </span>
                  </div>
                  <div className="stats-link pull-right">
                    <a
                      className="footer-link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Yachts
                    </a>
                  </div>
                </CardFooter>
                */ }
              </CardBody>
            </Card>
          </Col>
          <Col className="px-0" md="3">
            <Card className="card-fashion">
              <CardTitle tag="div">
                <h4>
                  <Link to="/sign-up">
                    Register to start using.
                  </Link>
                </h4>
              </CardTitle>
              <CardBody>
                <CardFooter>
                  <div className="stats">
                    <span>
                      
                    </span>
                    <span>
                      
                    </span>
                  </div>
                  <div className="stats-link pull-right">
                    <Link to="/pricing">
                      Jump in and Subscribe.
                    </Link>
                  </div>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
          <Col className="px-0" md="3">
            <Card
              className="card-fashion"
              style={{
                backgroundColor: "black"
              }}
            >
              <CardTitle tag="div">
                <h2>
                  <Link to="/pricing">
                    $129
                  </Link>
                </h2>
                per month
              </CardTitle>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SignupRow;
