import axios from "axios";

import * as types from "./types";
import {
  API_URL,
  ACTIVE, CANCELED, PENDING, SUSPENDED,
  formatUnixTimestamp
} from '../common/constants';
// user actions

export const createUser = (userData) => {
  if (userData.fullName) {
    //separate into firstName and lastName
    const [firstName, ...rest] = userData.fullName.split(" ");
    const lastName = rest.join(" ");
    userData.first_name = firstName;
    userData.last_name = lastName;
    delete userData.fullName;
  }
  
  return async (dispatch) => {
    try {
      // Make an API call to send registration data
      const response = await axios.post(`${API_URL}/user/create`, userData);
      console.log('createUser.response', response);

      if (response.status === 201) {
        const user = response.data;
        dispatch({
          type: 'CREATE_USER',
          payload: user
        });

        return user;
      } else {
        //throw new Error('Registration failed');
        const error = response?.data;
        return error;
      }
    } catch (error) {
      console.log('Error registering user: ', error);
      return false;
    }
  };
};

export const editUser = (userData) => {

};

export const deleteUser = (userData) => {

};

export const subscribe = (data) => {
  return async (dispatch) => {
    try {
      // Make an API call to send registration data
      const response = await axios.post(`${API_URL}/merchant/stripe/charge`, data);
      // console.log('subscribe.response', response);

      if (response?.data?.success === true) {
        const charge = response.data;
        const subscriptionData = {
          success: response.data.success,
          subscriptionId: charge.dbSubscription.id,
          gatewaySubscriptionId: charge.dbSubscription.gateway_subscription_id,
          gatewayPlanId: charge.dbSubscription.gateway_plan_id,
          status: charge.dbSubscription.status,
          startDate: charge.dbSubscription.start_date,
          endDate: charge.dbSubscription.end_date,
          nextChargeDate: charge.dbSubscription.next_charge_date,
          amount: charge.dbSubscription.amount
        };
        dispatch({
          type: 'CREATE_SUBSCRIPTION',
          payload: subscriptionData
        });

        const billingData = {
          success: response.data.success,
          billingId: charge.dbBilling.id,
          subscriptionId: charge.dbBilling.subscription_id,
          transactionId: charge.dbBilling.transaction_id,
          amount: charge.dbBilling.amount,
          cardNumber: charge.dbBilling.card_number,
          cardExpMonth: charge.dbBilling.card_exp_month,
          cardExpYear: charge.dbBilling.card_exp_year,
          responseCode: charge.dbBilling.gateway_response_code,
        };
        dispatch({
          type: 'CREATE_BILLING',
          payload: billingData
        });

        return true;
      } else {
        dispatch({
          type: 'CREATE_SUBSCRIPTION',
          payload: { success: response.data.success, error: response?.data?.error }
        });
        return false;
      }
    } catch (error) {
      dispatch({
        type: 'CREATE_SUBSCRIPTION',
        payload: { success: false, error: 'Error submitting payment' }
      });
      return false;
    }
  };
};

export const getUserDesignations = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_URL}/user/designation/list`);

      if (response.status === 200) {
        const designations = response.data;
        dispatch({
          type: 'GET_USER_DESIGNATIONS',
          payload: designations
        });

        return designations;
      } else {
        return false;
      }
    } catch (error) {
      console.log('Error getting user designations: ', error);
      return false;
    }
  };
};

export const userPurchase = (data) => {
  console.log('handleUserPurchase.data', data);
  return async (dispatch) => {
    dispatch({
      type: 'USER_PURCHASE',
      payload: data
    });
  };
};
