// Imports: Dependencies
import storage from 'redux-persist/lib/storage';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';

// Imports: Redux Root Reducer
import rootReducer from '../reducers';

const middlewares = [thunk];

if (process.env.REACT_APP_NODE_ENV === 'development') {
  const { logger } = require('redux-logger');
  middlewares.push(logger);
}

// Middleware: Redux Persist Config
const persistConfig = {
  // Timeout for the storage
  timeout: 10000,
  // Root key for storage
  key: 'root',
  // State reconciler
  stateReconciler: autoMergeLevel2,
  // Storage method (React Native)
  storage,
  // Debug mode (set to false in production)
  debug: true,
  // Whitelist of reducers to persist
  whitelist: ['authReducer', 'userReducer'],
  // Blacklist of reducers to exclude from persisting
  // blacklist: [],
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux: Store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

// Middleware: Redux Persist Persister
const persistor = persistStore(store);

// Exports
export { store, persistor };
